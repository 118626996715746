import {
  Avatar,
  Box,
  Flex,
  Heading,
  Menu,
  Text,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Image,
  IconButton,
  Grid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../../state/actions/userActions";
import { RootState } from "../../../../state/reducers";
import dotsIcon from "../../../../assets/icons/menu-icon.svg";
import { getArr, getDashboardApps } from "../../../../datas/data";
import MenuCard from "../../../modules/MenuCard/menuCard";
import homeIcon from "../../../../assets/icons/home-icon.svg";
import { appsData } from "../../../../model/Dashboard.interface";
import { DashModalBodyReqState } from "../../../../model/DashModal.interface";
import { dashModelApi } from "../../../../api/appServices/AppServices";
import { toast } from "react-toastify";
import DashModal from "../../../modules/dashModal/dashModal";
import { colors } from "../../../../theme/colors";
import { ROUTER_ENUM } from "../../../../model/Router.enum";
import ProfileSettingsIcon from "../../../../assets/images/dashboard/profileSettingsIcon";
import LogoutIcon from "../../../../assets/images/dashboard/logoutIcon";
import GenerateKeyModal from "../../../modules/generateModal/generateModal";
import GenerateIcon from "../../../../assets/images/dashboard/generateIcon";

interface HeaderProps {
  headerTitle: string;
}

const Header: React.FC<HeaderProps> = ({ headerTitle }) => {
  const navigate = useNavigate();
  const [isDashModalOpen, setIsDashModalOpen] = useState<boolean>(false);
  // const [isGenerateOpen, setIsGenerateOpen] = useState<boolean>(false);
  let [appDetails, setAppDetails] = useState<appsData>();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.userData);
  const { imageLocation } = user ?? {};
  const profileImageURL = imageLocation
    ? `${process.env.REACT_APP_IMAGE_HOST_URL}/${imageLocation}`
    : "";
  const { token } = user ?? {};

  const splitArray: string[] =
    user?.applicationName != null ? user?.applicationName.split(",") ?? [] : [];
  getArr(splitArray);

  const serializedData = encodeURIComponent(JSON.stringify(user));

  const handleSubmit = (item: appsData) => {
    setAppDetails(item);
    if (!item._new) {
      if (token) {
        window.open(
          `${process.env.REACT_APP_APPLINKS_URL}/${item.appName}?token=${token}&appDetails=${serializedData}`,
          "_blank"
        );
      }
    } else {
      setIsDashModalOpen(true);
    }
  };

  const HandleClose = () => {
    setIsDashModalOpen(false);
  };

  // const HandleGenerateClose = () => {
  //   setIsGenerateOpen(false);
  // };

  const handleSubmitRequest = async (textMessage: string) => {
    // call the API
    const body = {
      applicationRequest: {
        userId: user?.id,
        applicationNames: appDetails?.appName,
        comments: textMessage,
      },
      user: {
        id: user?.id,
        orgId: user?.orgId,
        emailAddress: user?.emailAddress,
        firstName: user?.firstName,
        lastName: user?.lastName,
        mobileNumber: user?.mobileNumber,
      },
    } as DashModalBodyReqState;
    const response = await dashModelApi(body, token ?? "");
    if (response?.statusCode == "0000") {
      toast.info(
        "Thanks for the new application request, our sales team will get in touch with you",
        { position: "top-center", theme: "colored", autoClose: 5000 }
      );
    } else {
      toast.error("Already Requested for this Application.", {
        position: "top-center",
        theme: "colored",
        autoClose: 5000,
      });
    }
    setIsDashModalOpen(false);
  };

  const goDashboard = () => {
    navigate(ROUTER_ENUM.DASHBOARD);
  };

  const profileMenu = [
    {
      title: "Profile Settings",
      Icon: () => {
        return <ProfileSettingsIcon />;
      },
      handler: () => {
        navigate("/profile");
      },
    },
    // {
    //   title: "Api Key Generate",
    //   Icon: () => {
    //     return <GenerateIcon />;
    //   },
    //   handler: () => {
    //     setIsGenerateOpen(true);
    //   },
    // },
    {
      title: "Logout",
      Icon: () => {
        return <LogoutIcon />;
      },
      handler: async () => {
        console.log("logging out....");
        await dispatch(logoutUser());
        navigate("/login");
      },
    },
  ];

  return (
    <Flex
      gap={2}
      w={"100%"}
      h={"55px"}
      borderBottom={"1px"}
      borderColor={"#DADADA"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Flex
        alignItems={"center"}
        w={100}
        h={"28px"}
        gap={"6px"}
        marginRight={"100px"}
      >
        <Box>
          <Heading
            marginBottom={0}
            color={colors.lightBlack}
            fontFamily={"roboto"}
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"14px"}
            letterSpacing={"0.8px"}
            textAlign={"end"}
            width={"150px"}
          >
            {`${user?.firstName} ${user?.lastName}`}
          </Heading>
          <Text
            marginBottom={0}
            color={colors.lightestGrey}
            fontFamily={"roboto"}
            fontWeight={400}
            fontSize={"10px"}
            lineHeight={"12px"}
            letterSpacing={"0.6px"}
            textAlign={"end"}
          >
            Welcome
          </Text>
        </Box>

        <Menu autoSelect={false}>
          <MenuButton
            backgroundColor={"white"}
            as={IconButton}
            icon={<Avatar h={"28px"} w={"28px"} src={profileImageURL} />}
          />

          <MenuList boxShadow={"md"}>
            {profileMenu.map((item, index) => (
              <MenuItem key={index} onClick={item.handler} gap={2}>
                <item.Icon />
                <Text marginTop={"auto"} marginBottom={"auto"}>
                  {item.title}
                </Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>

      <Box marginRight={4}>
        <Menu>
          <MenuButton
            backgroundColor={"white"}
            as={IconButton}
            _hover={{ backgroundColor: "#D1E5F4" }}
            icon={
              <Image
                height={"20px"}
                width={"20px"}
                ml={"auto"}
                mr={"auto"}
                src={dotsIcon}
              />
            }
          />

          <MenuList
            width={"100%"}
            height={"500px"}
            padding={"24px"}
            gap={"11px"}
            boxShadow={"md"}
            overflowY={"auto"}
          >
            <Box>
              <Grid
                templateColumns={"repeat(2, 1fr)"}
                gap={"11px"}
                w={"283px"}
                h={"477px"}
              >
                {getDashboardApps(token ?? "").map((item, index) => {
                  const { appIcon, headerTitle, _new } = item;
                  return (
                    <MenuCard
                      len={getDashboardApps(token || "").length}
                      _new={_new}
                      index={index}
                      appIcon={appIcon}
                      headerTitle={headerTitle}
                      handleAppButtonClick={() => {}}
                      handleSubmit={() => handleSubmit(item)}
                    />
                  );
                })}
              </Grid>
            </Box>

            <Box paddingTop={4}>
              <Button
                onClick={goDashboard}
                _hover={{ bg: "#d2f2d4" }}
                width={"283px"}
                height={"34px"}
                borderRadius={"4px"}
                border={"1px"}
                gap={"6px"}
                borderColor={"#2C8526"}
                backgroundColor={"white"}
              >
                <img src={homeIcon}></img>Go to Home
              </Button>
            </Box>
          </MenuList>
        </Menu>
      </Box>

      <DashModal
        handleSubmit={handleSubmitRequest}
        handleClose={HandleClose}
        isOpen={isDashModalOpen}
      />

      {/* <GenerateKeyModal
        isGenerateOpen={isGenerateOpen}
        handleGenerateClose={HandleGenerateClose}
      /> */}
    </Flex>
  );
};

export default React.memo(Header);
